export const CLOCKING_TOLERANCE_MINUTE_CHOICES = [
    {display_name: '15m', value: 15},
    {display_name: '30m', value: 30},
    {display_name: '45m', value: 45},
    {display_name: '1h', value: 60},
    {display_name: '1h 15m', value: 75},
    {display_name: '1h 30m', value: 90},
    {display_name: '1h 45m', value: 105},
    {display_name: '2h', value: 120},
    {display_name: 'Nessun controllo', value: null},
];

export const WORKING_TIME_TOLERANCE_MINUTE_CHOICES = [
    {display_name: '5m', value: 5},
    ...CLOCKING_TOLERANCE_MINUTE_CHOICES,
];

export const CLOCKING_TOLERANCE_MINUTE_DISPLAY_NAMES = {
    ...CLOCKING_TOLERANCE_MINUTE_CHOICES.reduce((acc, choice) => {
        acc[choice.value] = choice.display_name;
        return acc;
    }, {}),
};

export const WORKING_TIME_TOLERANCE_MINUTE_DISPLAY_NAMES = {
    ...WORKING_TIME_TOLERANCE_MINUTE_CHOICES.reduce((acc, choice) => {
        acc[choice.value] = choice.display_name;
        return acc;
    }, {}),
};

export const EMPLOYEE_ANOMALY_RESOLUTION_MODE_CHOICES = {
    ABSENCE_REQUEST: 'ABSENCE_REQUEST',
    ABSENCE_REQUEST_OR_MANUAL: 'ABSENCE_REQUEST_OR_MANUAL',
    NONE: 'NONE',
};
