import {PERSON_ROLE} from '@jetCommon/constants/person-constants';
import {api} from '@/api';
import {defineStore, storeToRefs} from 'pinia';
import {useAuthStore} from '@jetCommon/stores/auth.js';

export const useSpaStore = defineStore('spa', () => {
    const authStore = useAuthStore();
    const {isPersonAuthenticated, person} = storeToRefs(authStore);

    const company = ref(null);
    const companyId = ref(null);
    const companySettings = ref(null);
    const companyAddOns = ref(null);

    // TODO: add watch on companyId, employeeId and managerId that calls fetchCompany, fetchEmployee and fetchManager

    async function fetchCompany(expandSettings = false, expandSalaryPaymentSettings = false) {
        company.value =
            (
                await api.companies
                    .retrieve(companyId.value, {
                        ...(expandSettings && {expand_settings: true}),
                        ...(expandSalaryPaymentSettings && {
                            expand_salary_payment_settings: true,
                        }),
                    })
                    .catch(api.end)
            ).data ?? null;
        if (company.value && expandSettings) {
            companySettings.value = company.value.settings;
        }
        if (company.value) {
            companyAddOns.value =
                (await api.companies.retrieveCompanyAddOns(companyId.value).catch(api.end))?.data ?? null;
        }
    }

    async function fetchCompanySettings(expandSalaryPaymentSettings = true) {
        companySettings.value =
            (await api.companies.retrieveCompanySettings(companyId.value, expandSalaryPaymentSettings).catch(api.end))
                .data ?? null;
    }

    const adminRole = computed(() => {
        if (!companyId.value) {
            return null;
        }
        const intCompanyId = Number.parseInt(companyId.value, 10);
        return (
            isPersonAuthenticated.value &&
            (person.value.companies
                .find(c => c.id === intCompanyId)
                ?.roles.find(role => role.kind === PERSON_ROLE.ADMIN) ||
                null)
        );
    });

    async function onJetRouteEntityValueChange(jetRouteEntityValue) {
        companyId.value = jetRouteEntityValue;
        await fetchCompany({
            expandSettings: true,
            expandSalaryPaymentSettings: true,
        });
        await fetchCompanySettings();
    }

    /**
     * Update company last revision
     * @param {string} last_revision
     * @returns {void}
     */
    function updateCompanyLastRevision(last_revision) {
        if (companySettings.value) {
            companySettings.value.last_dvr_revision_date = last_revision;
        }
    }

    return {
        adminRole,
        company,
        companyId,
        companySettings,
        companyAddOns,
        fetchCompany,
        fetchCompanySettings,
        onJetRouteEntityValueChange,
        updateCompanyLastRevision,
    };
});
