<script setup>
    import JetCallout from '@jetDS/components/JetCallout.vue';
    import JetDivider from '@jetDS/components/JetDivider.vue';
    import JetForm from '@jetDS/components/JetForm.vue';
    import JetFormItem from '@jetDS/components/JetFormItem.vue';
    import JetInput from '@jetDS/components/JetInput.vue';
    import JetSelect from '@jetDS/components/JetSelect.vue';

    const props = defineProps({
        readOnly: {
            type: Boolean,
            default: false,
        },
        task: {
            type: Object,
            required: true,
        },
    });

    const emit = defineEmits(['submitted']);

    const {$api, $jetMessage} = inject('jet');
    const formRef = ref(null);

    const formData = reactive({
        pay_day: props.task.submitted_data?.pay_day || null,
        pay_month: props.task.submitted_data?.pay_month || null,
        absences: props.task.submitted_data?.absences,
    });
    const requiredFieldRule = {required: true, message: 'Campo obbligatorio', trigger: 'blur'};
    const formRules = computed(() => ({
        pay_day: [
            requiredFieldRule,
            {
                validator: (rule, value, callback) => {
                    const intValue = parseInt(value, 10);
                    if (isNaN(intValue)) {
                        callback(new Error('Il valore inserito deve essere un numero valido'));
                    } else if (value < 0 || value > 31) {
                        callback(new Error('Il valore inserito deve essere tra 1 e 31'));
                    } else {
                        callback();
                    }
                },
                trigger: 'blur',
            },
        ],
        pay_month: [requiredFieldRule],
        absences: [requiredFieldRule],
    }));

    const payMonthChoices = ref([]);
    $api.companySettings
        .getPayMonthChoices()
        .then(response => {
            payMonthChoices.value = response.data;
        })
        .catch($api.end);
    const selectedPayMonthChoiceDisplayName = computed(() => {
        return payMonthChoices.value.find(choice => choice.value === formData.pay_month)?.display_name || '';
    });
    const showPayDateCallout = computed(() => {
        return formData.pay_day && formData.pay_day > 0 && formData.pay_day <= 31 && formData.pay_month;
    });

    const absencesExpensesChoices = ref([]);
    $api.companySettings
        .getAbsencesExpensesChoices()
        .then(response => {
            absencesExpensesChoices.value = response.data;
        })
        .catch($api.end);

    async function submit() {
        if (props.readOnly) {
            $jetMessage.warning('Non puoi modificare i dati in questo momento.');
            return;
        }

        const formEl = formRef.value.getElFormRef();
        const valid = await formEl.validate().catch(() => false);
        if (valid) {
            $api.companyOnboardingTasks
                .submitData(props.task.id, {
                    ...formData,
                })
                .then(() => emit('submitted'))
                .catch($api.end);
        }
    }

    defineExpose({
        formData,
        submit,
    });
</script>

<template>
    <JetForm ref="formRef" :rules="formRules" :model="formData">
        <div class="PayrollDatesForm_Row">
            <div class="PayrollDatesForm_PayDayCol">
                <JetFormItem prop="pay_day" label="Giorno del mese">
                    <JetInput v-model="formData.pay_day" :disabled="readOnly" />
                </JetFormItem>
            </div>
            <div class="PayrollDatesForm_PayMonthCol">
                <JetFormItem prop="pay_month" label="Seleziona il mese di riferimento">
                    <JetSelect
                        v-model="formData.pay_month"
                        :disabled="readOnly"
                        filterable
                        full-width
                        placeholder="Seleziona"
                        :options="payMonthChoices" />
                </JetFormItem>
            </div>
        </div>

        <JetCallout v-if="showPayDateCallout" type="info" plain>
            Pagherai gli stipendi ogni volta il {{ formData.pay_day }} del
            {{ selectedPayMonthChoiceDisplayName.toLowerCase() }}.
        </JetCallout>

        <JetDivider />

        <JetFormItem prop="absences" label="Gestione presenze e assenze">
            <JetSelect
                v-if="absencesExpensesChoices.length > 0"
                v-model="formData.absences"
                :disabled="readOnly"
                filterable
                full-width
                placeholder="Gestione presenze e assenze"
                option-height-fluid
                :options="absencesExpensesChoices">
                <template #optionCaption="{option}">
                    {{ option.caption }}
                </template>
            </JetSelect>
        </JetFormItem>

        <JetDivider />

        <JetCallout class="mt-4" type="warning" plain>
            Una volta salvati questi campi non sarà più possibile modificarli. Se hai fatto un errore contatta
            l'assistenza.
        </JetCallout>
    </JetForm>
</template>

<style lang="scss" scoped>
    .PayrollDatesForm {
        &_Row {
            display: flex;
            gap: 16px;
        }

        &_PayDayCol {
            flex: 1 1 35%;
        }

        &_PayMonthCol {
            flex: 1 1 65%;
        }
    }
</style>
