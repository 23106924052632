/*
 * This JS module can be used to configure a new type of policy.
 * Once these constants are defined, the custom settings module
 * must be defined in company-policy/{policy-name}/PolicySettings{PolicyName}.vue
 * and added injected in company-policy/views/CompanyPolicyView.vue
 */
import {CONTRACT_STATUS, CONTRACT_KIND} from '@jetCommon/constants/employee-constants.js';

import ClockingPolicySettings from './clocking/components/ClockingPolicySettings.vue';
import PolicySettingsExpense from '@/features/company-policy/expense/components/PolicySettingsExpense.vue';
import PolicySettingsHeadquarters from '@/features/company-policy/headquarters/components/PolicySettingsHeadquarters.vue';
import PolicySettingsTripDailyRefund from '@/features/company-policy/trip-daily-refund/components/PolicySettingsTripDailyRefund.vue';
import TripDailyRefundHowItWorksModal from '@/features/company-policy/trip-daily-refund/components/TripDailyRefundHowItWorksModal.vue';

export const COMPANY_POLICY_CATEGORY = {
    EXPENSE: 'EXPENSE',
    MEAL_VOUCHER: 'MEAL_VOUCHER',
    HEADQUARTERS: 'HEADQUARTERS',
    CLOCKING: 'CLOCKING',
    TRIP_DAILY_REFUND: 'TRIP_DAILY_REFUND',
};

/**
 * @typedef {Object} CompanyPolicyConfig
 * @property {string} routerKey - The router key for the policy
 * @property {string|null} companySettingsIsEnabledField - The company settings field that enables the policy.
 * Set it to NULL if the policy is always enabled and cannot be disabled
 * @property {string} title - The title of the policy
 * @property {string} groupLabel - The label of the policy group
 * @property {string|null} sectionRoute - The route to for a dedicated policy section, if any.
 * Set it to NULL to hide the "Vai alla sezione" link.
 * @property {string} policyAPI - The class name to handle API query
 * @property {boolean} canAdminSetEnabledStatus - Whether the admin can set the enabled status
 * @property {object} settingsComponent - The form to set policy properties, as SFC.
 *  This component accepts a prop `policy` which is the policy object
 *  and emits an event @updated-form which should be triggered when the
 *  policy form is updated.
 * @property {object|null} howItWorksModalComponent - The modal to show the "How it works" section
 * @property {object} employeeQueryParams - Query params to filter employees (e.g. by contract status)
 * @property {string|null} assigneeTableInfoCalloutContent - The callout text for the assignee table page
 * @property {string|null} addPolicyButtonLabel - The custom label for "Aggiungi policy" button
 * @property {string|null} assignPolicyColumnLabel - The custom label for the "Assegna policy" column
 */

/**
 * @readonly
 * @enum {CompanyPolicyConfig}
 */
export const COMPANY_POLICY_CONFIGS = {
    [COMPANY_POLICY_CATEGORY.EXPENSE]: {
        routerKey: 'rimborsi-spese',
        companySettingsIsEnabledField: 'expenses_enabled',
        title: 'Rimborsi spese',
        groupLabel: 'Policy rimborsi spese',
        sectionRoute: 'expensesView',
        policyAPI: 'companyExpensePolicy',
        canAdminSetEnabledStatus: true,
        // This component accepts a prop `policy` which is the policy object
        // and emits an event @updated-form which should be triggered when the
        // policy form is updated.
        settingsComponent: PolicySettingsExpense,
        employeeQueryParams: {
            contract_status__in: `${CONTRACT_STATUS.IN_FORCE},${CONTRACT_STATUS.INCOMING}`,
        },
    },
    [COMPANY_POLICY_CATEGORY.MEAL_VOUCHER]: {
        // TODO: refactor meal voucher to use new company policy configuration
        companySettingsIsEnabledField: 'meal_vouchers_enabled',
        title: 'Buoni pasto',
        policyAPI: 'companyMealVoucherPolicies',
    },
    [COMPANY_POLICY_CATEGORY.CLOCKING]: {
        routerKey: 'timbrature',
        companySettingsIsEnabledField: 'clockings_enabled',
        title: 'Timbrature',
        groupLabel: 'Policy timbrature',
        // TODO: to be defined once we develop the feature
        sectionRoute: '',
        policyAPI: 'companyClockingPolicy',
        canAdminSetEnabledStatus: false,
        settingsComponent: ClockingPolicySettings,
        assigneeTableInfoCalloutContent:
            'Puoi assegnare una policy di timbratura solamente alle persone con orario fisso.',
        employeeQueryParams: {
            contract_status__in: `${CONTRACT_STATUS.IN_FORCE},${CONTRACT_STATUS.INCOMING}`,
            working_hours_kind: 'FIXED',
        },
    },
    [COMPANY_POLICY_CATEGORY.TRIP_DAILY_REFUND]: {
        routerKey: 'diaria-trasferta',
        companySettingsIsEnabledField: 'trip_daily_refund_enabled',
        title: 'Diaria per trasferta',
        groupLabel: 'Policy diaria per trasferta',
        policyAPI: 'companyTripDailyRefundPolicy',
        canAdminSetEnabledStatus: true,
        // This component accepts a prop `policy` which is the policy object
        // and emits an event @updated-form which should be triggered when the
        // policy form is updated.
        settingsComponent: PolicySettingsTripDailyRefund,
        howItWorksModalComponent: TripDailyRefundHowItWorksModal,
        assigneeTableInfoCalloutContent:
            'Puoi assegnare una policy di diaria per trasferta solamente alle persone con contratto da dipendente, mentre sono esclusi i collaboratori e i tirocinanti.',
        employeeQueryParams: {
            contract_status__in: `${CONTRACT_STATUS.IN_FORCE},${CONTRACT_STATUS.INCOMING}`,
            in_day_contract_kind__in: CONTRACT_KIND.EMPLOYEE,
        },
    },
    [COMPANY_POLICY_CATEGORY.HEADQUARTERS]: {
        routerKey: 'sedi-aziendali',
        companySettingsIsEnabledField: null,
        title: 'Sedi aziendali',
        groupLabel: 'Elenco delle sedi',
        sectionRoute: null,
        policyAPI: 'companyHeadquartersPolicy',
        canAdminSetEnabledStatus: false,
        settingsComponent: PolicySettingsHeadquarters,
        assigneeTableInfoCalloutContent: 'I dipendenti sprovvisti di sede verranno assegnati alla Sede Legale.',
        addPolicyButtonLabel: 'Aggiungi sede',
        assignPolicyColumnLabel: 'Assegna sede',
    },
};

export const ROUTER_KEYS = Object.keys(COMPANY_POLICY_CONFIGS).map(key => COMPANY_POLICY_CONFIGS[key].routerKey);
