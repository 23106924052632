import SafetyAndHealthApiCommon from '@jetCommon/api/safety-and-health.js';

export default class SafetyAndHealthApi extends SafetyAndHealthApiCommon {
    showInterest(data) {
        return this.apiPost('show_interest/', data);
    }

    uploadNewDVR(companyId, data) {
        return this.apiPost(`${companyId}/upload_new_dvr/`, data);
    }

    create(data) {
        return this.apiPost('', data);
    }

    update(safetyAndHealthId, data) {
        return this.apiPatch(`${safetyAndHealthId}/`, data);
    }

    deleteCompanyRole(id) {
        return this.apiDelete(`${id}/`);
    }

    getCounts(params) {
        return this.apiGet('counts/', {params});
    }

    getRoleChoices(params) {
        return this.apiGet('role_choices/', {params});
    }

    sendInvitationToEmployeesWithUnknownStatus(params) {
        return this.apiPost('send_invitation_to_employees_with_unknown_status/', {params});
    }

    sendInvitationToEmployeeWithUnknownStatus(id) {
        return this.apiPost(`${id}/send_invitation_to_employee_with_unknown_status/`);
    }

    resetData(id) {
        return this.apiPost(`${id}/reset_data/`);
    }

    updateKind(id, data) {
        return this.apiPost(`${id}/update_kind/`, data);
    }
}
