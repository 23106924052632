import {ANOMALY_KIND} from '@jetCommon/constants/clocking-constants.js';
import {durationFromTotalMinutes, formatDuration} from '@jetCommon/helpers/time';

const formatAnomalyMessage = anomaly => {
    switch (anomaly.kind) {
        case ANOMALY_KIND.MISSING:
            return 'Mancante';
        case ANOMALY_KIND.UNEXPECTED:
            return 'Non prevista';
        case ANOMALY_KIND.CLOCKING_TIMING:
            if (anomaly.delta_minutes < 0) {
                return `${formatDuration(durationFromTotalMinutes(Math.abs(anomaly.delta_minutes)), {seconds: false})} di anticipo`;
            }

            return `${formatDuration(durationFromTotalMinutes(anomaly.delta_minutes), {seconds: false})} di ritardo`;
        case ANOMALY_KIND.WORKDAY_HOURS:
            if (anomaly.delta_minutes < 0) {
                return `${formatDuration(durationFromTotalMinutes(Math.abs(anomaly.delta_minutes)), {seconds: false})} in meno`;
            }

            return `${formatDuration(durationFromTotalMinutes(anomaly.delta_minutes), {seconds: false})} in in più`;
    }
};

function formatTime(hours, minutes) {
    if (hours === 0 && minutes === 0) {
        return '0m';
    } else if (hours === 0) {
        return `${minutes}m`;
    } else if (minutes === 0) {
        return `${hours}h`;
    }
    return `${hours}h ${minutes}m`;
}

function formatWorkedTimeText(workedMinutes) {
    const {hours, minutes} = durationFromTotalMinutes(workedMinutes);
    return formatTime(hours, minutes);
}

function dailyClockingSurrogateKey(employeeId, date) {
    if (date === 'today') {
        return date;
    }

    return `${employeeId}-${date}`;
}

export {formatAnomalyMessage, formatTime, formatWorkedTimeText, dailyClockingSurrogateKey};
