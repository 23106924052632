<script setup>
    import {
        EXPENSE_KIND,
        EXPENSE_PAYSLIP_EXPORT_TYPE,
    } from '@jetCommon/features/expense/constants/expense-constants.js';
    import {formatAmount} from '@jetCommon/helpers/amount.js';

    import DialogEditableInputOptions from '@jetCommon/components/DialogEditableInputOptions.vue';
    import ExpenseKindLabel from '@jetCommon/features/expense/components/ExpenseKindLabel.vue';
    import JetButton from '@jetDS/components/JetButton.vue';
    import JetCallout from '@jetDS/components/JetCallout.vue';
    import JetCurrencyInput from '@jetDS/components/JetCurrencyInput.vue';
    import JetDialog from '@jetDS/components/JetDialog.vue';
    import JetForm from '@jetDS/components/JetForm.vue';
    import JetFormItem from '@jetDS/components/JetFormItem.vue';
    import JetInfoLine from '@jetDS/components/JetInfoLine.vue';
    import JetInfoLineGroup from '@jetDS/components/JetInfoLineGroup.vue';
    import JetInput from '@jetDS/components/JetInput.vue';
    import JetRadioButton from '@jetDS/components/JetRadioButton.vue';
    import JetRadioGroup from '@jetDS/components/JetRadioGroup.vue';
    import JetSelect from '@jetDS/components/JetSelect.vue';
    import JetSwitch from '@jetDS/components/JetSwitch.vue';

    const props = defineProps({
        policy: {
            type: Object,
            required: true,
        },
    });

    const emit = defineEmits(['updated-form']);

    const {$api} = inject('jet');

    const defaultCompanyExpensePolicy = computed(() => ({
        name: '',
        enable_ai_suggestions: true,
        export_type: EXPENSE_PAYSLIP_EXPORT_TYPE.SINGLE,
        not_refund_option: false,
        not_refund_expense_need_approval: false,
        additional_export_field_name: '',
        additional_export_field_description: '',
        additional_export_field_required: false,
        available_standard_kinds: [
            ...Object.keys(EXPENSE_KIND)
                .filter(kind => kind !== EXPENSE_KIND.CUSTOM)
                .map(kind => ({
                    kind: EXPENSE_KIND[kind],
                    display_name: null,
                    max_refund_amount: null,
                })),
        ],
        available_custom_kinds: [],
    }));

    const formRef = ref(null);
    const formData = ref({
        ...defaultCompanyExpensePolicy.value,
        ...props.policy,
    });
    emit('updated-form', formData.value);

    watch(
        formData,
        () => {
            emit('updated-form', formData.value);
        },
        {deep: true},
    );

    const formRules = {
        name: [{required: true, message: 'Inserire il titolo della policy', trigger: 'change'}],
        enable_ai_suggestions: [{required: true, message: 'Inserire un valore', trigger: 'change'}],
        export_type: [{required: true, message: 'Inserire un valore', trigger: 'change'}],
        not_refund_option: [{required: true, message: 'Inserire un valore', trigger: 'change'}],
        not_refund_expense_need_approval: [{required: true, message: 'Inserire un valore', trigger: 'change'}],
    };

    function isFormValid() {
        return formRef.value.getElFormRef().validate();
    }

    const expenseKindChoices = ref([]);
    const standardKindOptions = ref([]); // Array of selected standard kind options
    const standardKindOptionsMaxRefund = ref({}); // Object with max refund amount for each standard kind
    const customKindOptions = ref([]); // All data for custom kind options
    const editModeCustomKind = ref(false);
    const editModeKindRefundLimits = ref(false);
    const editModeAdditionalExportField = ref(false);

    const customKindOptionsFullLabels = computed(() => {
        if (customKindOptions.value.length === 0) {
            return 'Nessuna categoria personalizzata';
        }
        return customKindOptions.value.map(option => option.display_name).join(', ');
    });

    const hasRefundLimits = computed(() => {
        for (const kind of standardKindOptions.value) {
            if (standardKindOptionsMaxRefund.value[kind] >= 1) {
                return true;
            }
        }
        for (const kind of customKindOptions.value) {
            if (kind.max_refund_amount >= 1) {
                return true;
            }
        }
        return false;
    });

    const hasSelectedKinds = computed(() => {
        return standardKindOptions.value.length > 0 || customKindOptions.value.length > 0;
    });

    function rebuildFormKinds() {
        const available_standard_kinds = [];
        standardKindOptions.value.forEach(kind_type => {
            available_standard_kinds.push({
                kind: kind_type,
                display_name: null,
                max_refund_amount:
                    standardKindOptionsMaxRefund.value[kind_type] >= 1
                        ? standardKindOptionsMaxRefund.value[kind_type]
                        : null,
            });
        });

        const available_custom_kinds = [];
        customKindOptions.value.forEach(kind => {
            available_custom_kinds.push({
                kind: EXPENSE_KIND.CUSTOM,
                display_name: kind.display_name,
                max_refund_amount: kind.max_refund_amount >= 1 ? kind.max_refund_amount : null,
            });
        });

        formData.value = {
            ...formData.value,
            available_standard_kinds,
            available_custom_kinds,
        };
    }

    watch(
        standardKindOptions,
        () => {
            rebuildFormKinds();
        },
        {deep: true},
    );

    function handleCustomKindOptionsConfirm(options) {
        // Assigning an id to each new option and setting max_refund_amount
        // The id is used to identify the option in the form, but it is not send to the backend
        let i = Math.max(...options.map(o => o.id));
        for (const option of options) {
            if (option.id === null) {
                option.id = ++i;
                option.max_refund_amount = null;
            }
        }
        customKindOptions.value = options;
        editModeCustomKind.value = false;
        rebuildFormKinds();
    }

    function getDataFromPolicy() {
        standardKindOptions.value = props.policy.available_standard_kinds.map(kind => kind.kind);
        standardKindOptionsMaxRefund.value = {};
        props.policy.available_standard_kinds.forEach(
            kind => (standardKindOptionsMaxRefund.value[kind.kind] = kind.max_refund_amount),
        );

        let i = 0;
        customKindOptions.value = props.policy.available_custom_kinds.map(kind => ({
            ...kind,
            id: ++i,
        }));
    }

    function getExpenseKindChoices() {
        $api.expenses
            .kindChoices()
            .then(response => {
                expenseKindChoices.value = response.data;
                getDataFromPolicy();
            })
            .catch($api.end);
    }

    function handleKindRefundLimitsConfirm() {
        editModeKindRefundLimits.value = false;
        rebuildFormKinds();
    }

    function handleAdditionalFieldConfirm() {
        formData.value.additional_export_field_name = formData.value.additional_export_field_name?.trim();
        if (!formData.value.additional_export_field_name) {
            formData.value.additional_export_field_description = '';
            formData.value.additional_export_field_required = false;
        }
        editModeAdditionalExportField.value = false;
    }

    getExpenseKindChoices();

    defineExpose({
        isFormValid,
    });
</script>

<template>
    <JetInfoLineGroup>
        <JetForm ref="formRef" :rules="formRules" :model="formData" label-position="top">
            <JetInfoLine
                label="Titolo della policy"
                action-label=""
                description="Assegna un titolo alla policy per distinguerla facilmente dalle altre">
                <JetFormItem prop="name">
                    <JetInput v-model="formData.name" class="TextInput"></JetInput>
                </JetFormItem>
            </JetInfoLine>
            <JetInfoLine
                label="Estrazione dati dal giustificativo"
                action-label=""
                description="Puoi attivare l'analisi automatica del giustificativo per estrarre i dati e velocizzare
                il processo di inserimento.">
                <JetFormItem prop="enable_ai_suggestions">
                    <JetRadioGroup v-model="formData.enable_ai_suggestions">
                        <JetRadioButton :label="true">Attiva</JetRadioButton>
                        <JetRadioButton :label="false">Disattiva</JetRadioButton>
                    </JetRadioGroup>
                </JetFormItem>
            </JetInfoLine>
            <JetInfoLine
                label="Visualizzazione rimborsi nei cedolini"
                action-label=""
                description="Specifica come vuoi che i rimborsi spese vengano visualizzati all'interno del cedolino.">
                <JetFormItem prop="export_type">
                    <JetRadioGroup v-model="formData.export_type">
                        <JetRadioButton :label="EXPENSE_PAYSLIP_EXPORT_TYPE.SINGLE">
                            Una voce per ciascun rimborso
                        </JetRadioButton>
                        <JetRadioButton :label="EXPENSE_PAYSLIP_EXPORT_TYPE.CUMULATIVE">
                            Unica voce cumulativa
                        </JetRadioButton>
                    </JetRadioGroup>
                </JetFormItem>
            </JetInfoLine>
            <JetInfoLine
                label="Categorie di rimborso principali"
                action-label=""
                description="Indica quali categorie di rimborso vuoi abilitare tra quelle rese a disposizione da Jet HR.">
                <JetFormItem prop="available_standard_kinds">
                    <JetSelect
                        v-model="standardKindOptions"
                        class="TextInput"
                        placeholder="Seleziona categorie"
                        full-width
                        with-select-all
                        multiple
                        :options="expenseKindChoices">
                        <template #selectedOptionPrefix="{option}">
                            <ExpenseKindLabel :kind="option.value" />
                        </template>
                        <template #optionPrefix="{option}">
                            <ExpenseKindLabel :kind="option.value" />
                        </template>
                    </JetSelect>
                </JetFormItem>
            </JetInfoLine>
            <JetInfoLine
                v-model:editMode="editModeCustomKind"
                label="Categorie di rimborso personalizzate"
                description="Puoi aggiungere delle categorie di rimborso specifiche per la tua azienda.">
                <JetFormItem prop="available_custom_kinds">
                    {{ customKindOptionsFullLabels }}
                </JetFormItem>
                <template #lineEdit>
                    <DialogEditableInputOptions
                        v-if="editModeCustomKind"
                        value-key="id"
                        title="Categorie di rimborso personalizzate"
                        :options="customKindOptions"
                        @close="() => (editModeCustomKind = false)"
                        @confirm="handleCustomKindOptionsConfirm">
                        <template #optionsDeleted>
                            <JetCallout plain type="warning" class="mt-2">
                                Hai eliminato una o più opzioni. I rimborsi spese già inseriti non subiranno modifiche.
                                Non sarà più possibile selezionare queste opzioni nei rimborsi spese futuri.
                            </JetCallout>
                        </template>
                        <template #optionsRenamed>
                            <JetCallout plain type="warning" class="mt-2">
                                Hai rinominato una o più opzioni. I dati dei rimborsi spese eventualmente associati a
                                queste opzioni non verranno modificati.
                            </JetCallout>
                        </template>
                    </DialogEditableInputOptions>
                </template>
            </JetInfoLine>
            <JetInfoLine
                v-model:editMode="editModeKindRefundLimits"
                label="Limiti rimborsabili"
                description="Puoi indicare per ciascuna categoria un limite rimborsabile."
                :action-label="hasSelectedKinds ? 'Modifica' : ''">
                <JetFormItem prop="max_refund_amount" class="ExpenseRefundItemsContainer">
                    <template v-if="!hasSelectedKinds">Nessuna categoria selezionata</template>
                    <template v-else-if="!hasRefundLimits">Nessun limite impostato</template>
                    <template v-for="option in standardKindOptions">
                        <div v-if="standardKindOptionsMaxRefund[option]" :key="option" class="ExpenseRefundItem">
                            <div class="ExpenseRefundItem_Name">
                                {{ expenseKindChoices.find(o => o.value === option).display_name }}
                            </div>
                            <div>Limite rimborsabile {{ formatAmount(standardKindOptionsMaxRefund[option]) }}</div>
                        </div>
                    </template>
                    <template v-for="option in customKindOptions">
                        <div v-if="option.max_refund_amount" :key="option.id" class="ExpenseRefundItem">
                            <div class="ExpenseRefundItem_Name medium">
                                {{ option.display_name }}
                            </div>
                            <div>Limite rimborsabile {{ formatAmount(option.max_refund_amount) }}</div>
                        </div>
                    </template>
                </JetFormItem>
                <template #lineEdit>
                    <JetDialog
                        v-if="editModeKindRefundLimits"
                        title="Limiti rimborsabili"
                        show
                        :before-close="() => (editModeKindRefundLimits = false)"
                        destroy-on-close>
                        <template #body>
                            <div class="primary mb-4 medium">
                                Puoi indicare per ciascuna categoria un limite rimborsabile.
                            </div>
                            <JetFormItem
                                v-for="option in standardKindOptions"
                                :key="option"
                                :label="expenseKindChoices.find(o => o.value === option).display_name">
                                <JetCurrencyInput
                                    v-model="standardKindOptionsMaxRefund[option]"
                                    clearable
                                    placeholder="Nessun limite" />
                            </JetFormItem>
                            <JetFormItem
                                v-for="option in customKindOptions"
                                :key="option.id"
                                :label="option.display_name">
                                <JetCurrencyInput
                                    v-model="option.max_refund_amount"
                                    clearable
                                    placeholder="Nessun limite" />
                            </JetFormItem>
                        </template>
                        <template #footer>
                            <JetButton size="small" @click="() => (editModeKindRefundLimits = false)">
                                Annulla
                            </JetButton>
                            <JetButton type="primary" size="small" @click="handleKindRefundLimitsConfirm">
                                Conferma
                            </JetButton>
                        </template>
                    </JetDialog>
                </template>
            </JetInfoLine>
            <JetInfoLine
                label="Spese da non rimborsare in cedolino"
                action-label=""
                description="Permetti ai dipendenti di inserire spese da non rimborsare in cedolino (es. sostenute
                con carta aziendale).">
                <JetFormItem prop="not_refund_option">
                    <JetRadioGroup v-model="formData.not_refund_option">
                        <JetRadioButton label="true">Abilitate</JetRadioButton>
                        <JetRadioButton label="false">Disabilitate</JetRadioButton>
                    </JetRadioGroup>
                </JetFormItem>
            </JetInfoLine>
            <JetInfoLine
                v-if="String(formData.not_refund_option) === 'true'"
                label="Approvazione per le spese da non rimborsare"
                action-label="">
                <JetFormItem prop="not_refund_expense_need_approval">
                    <JetSelect
                        v-model="formData.not_refund_expense_need_approval"
                        :options="[
                            {
                                value: false,
                                display_name: 'Non richiesta',
                                caption: 'I rimborsi spese da non rimborsare verranno approvati in automatico',
                            },
                            {
                                value: true,
                                display_name: 'Richiesta',
                                caption:
                                    'I rimborsi spese da non rimborsare seguiranno il normale processo di approvazione',
                            },
                        ]"
                        option-height-fluid
                        full-width
                        class="TextInput">
                        <template #optionCaption="{option}">
                            {{ option.caption }}
                        </template>
                    </JetSelect>
                </JetFormItem>
            </JetInfoLine>
            <JetInfoLine
                v-model:edit-mode="editModeAdditionalExportField"
                label="Campo aggiuntivo"
                description="Puoi indicare un campo aggiuntivo che potrà essere associato alle richieste di rimborso.
                Il campo aggiuntivo sarà disponibile negli export in modo da permettere un'analisi dei costi più
                approfondita (es. codice progetto).">
                <JetFormItem prop="additional_export_field_name" class="ExpenseRefundItemsContainer">
                    <template v-if="formData.additional_export_field_name">
                        <div class="ExpenseRefundItem">
                            <div class="ExpenseRefundItem_Name medium">Titolo</div>
                            <div>{{ formData.additional_export_field_name }}</div>
                        </div>
                        <div class="ExpenseRefundItem">
                            <div class="ExpenseRefundItem_Name medium">Descrizione</div>
                            <div>
                                {{
                                    formData.additional_export_field_description
                                        ? formData.additional_export_field_description
                                        : '-'
                                }}
                            </div>
                        </div>
                        <div class="ExpenseRefundItem">
                            <div class="ExpenseRefundItem_Name medium">Campo obbligatorio</div>
                            <div>{{ formData.additional_export_field_required ? 'Sì' : 'No' }}</div>
                        </div>
                    </template>
                    <template v-else>-</template>
                </JetFormItem>
                <template #lineEdit>
                    <JetDialog
                        v-if="editModeAdditionalExportField"
                        title="Campo aggiuntivo"
                        show
                        :before-close="() => (editModeAdditionalExportField = false)"
                        destroy-on-close>
                        <template #body>
                            <JetFormItem label="Titolo del campo aggiuntivo">
                                <JetInput v-model="formData.additional_export_field_name" clearable />
                            </JetFormItem>
                            <JetFormItem
                                label="Descrizione del campo aggiuntivo (opzionale)"
                                help-text="Scrivi una descrizione per aiutare i dipendenti a inserire correttamente i valori">
                                <JetInput v-model="formData.additional_export_field_description" clearable />
                            </JetFormItem>
                            <JetFormItem class="AdditionalField_Switch">
                                <p class="base medium">Rendi il campo obbligatorio</p>
                                <JetSwitch v-model="formData.additional_export_field_required" />
                            </JetFormItem>
                            <JetCallout plain type="info" class="mt-2">
                                Il dipendente potrà inserire un valore a scelta fra quelli già inseriti o crearne di
                                nuovi
                            </JetCallout>
                        </template>
                        <template #footer>
                            <JetButton size="small" @click="() => (editModeAdditionalExportField = false)">
                                Annulla
                            </JetButton>
                            <JetButton type="primary" size="small" @click="handleAdditionalFieldConfirm">
                                Conferma
                            </JetButton>
                        </template>
                    </JetDialog>
                </template>
            </JetInfoLine>
        </JetForm>
    </JetInfoLineGroup>
</template>

<style scoped lang="scss">
    .TextInput {
        max-width: 320px;
    }

    .ExpenseRefundItemsContainer {
        :deep(.el-form-item__content) {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
        }
    }
    .ExpenseRefundItem_Name {
        color: var(--jet-text-color-secondary);
    }

    .AdditionalField_Switch {
        :deep(.el-form-item__content) {
            display: flex;
            justify-content: space-between;
        }
    }
</style>
