import {ApiBase} from '@jetCommon/api/base.js';

export default class EmployeeShiftApiCommon extends ApiBase {
    static resourceName = 'employee_shifts';

    bulkPublish(data) {
        return this.apiPost(`bulk_publish/`, data);
    }

    bulkDelete(data) {
        return this.apiDelete(`bulk_delete/`, {
            data,
        });
    }

    getCalendarSetup(config) {
        return this.apiGet(`calendar_setup/`, config);
    }

    getCalendarShift(config) {
        return this.apiGet(`calendar_shift/`, config);
    }

    getCalendarEmployeeChoices(params) {
        return this.apiGet(`calendar_employee_choices/`, {params});
    }
}
